import React from 'react'
import '../Styles/footer.css'
import logo from '../Header/logo.jpg'

const Footer = () => {
  return (
    <div>
      <section className='footer__below'>
       <div className='footer__sec'>
         <div className='row_footer'>
           <div className='col_footerb'>
             <img src={logo} alt="logo" />
             <br /><br />
             <p>33% Fund is centered on empowering you to navigate the intricate landscape of investments with confidence and clarity. We recognize that financial success is not just about accumulating wealth; it's about fulfilling dreams, securing futures, and embracing opportunities. To us, your financial aspirations are the heart of our endeavor. </p>
           </div>
           <div className='col_footer'>
            <h2>Company</h2>
            <br /><br />
            <a href="/About-Us">About</a>
            <br /><br />
            <a href="/Contact-Us">Contact Us</a>
            <br /><br />
            <a href="/Investment-Strategy">Resources</a>
            <br /><br />
            <a href="/Faq">Faq</a>
           </div>
           <div className='col_footer'>
            <h2>Contact Us</h2>
            <br /><br />
            <i class="ri-phone-fill"></i>

            <a href="tel:346 387 7187" >&nbsp;&nbsp;346 387 7187</a>
            <br /><br />
            <i class="ri-mail-line"></i>
            <a href="mailto:info@33percentfund.com" >&nbsp;&nbsp;info@33percentfund.com</a>
            <br /><br />
            <i class="ri-map-pin-2-fill"></i>

            <a href='#'>&nbsp;&nbsp;24285 Katy Freeway, Suite 300 Katy TX 77494</a>
           </div>
           <div className='col_footer'>
           <a href="https://www.facebook.com/profile.php?id=61572860183484" target='_blank'>           <i class="ri-facebook-fill" ></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
          <a href='https://www.instagram.com/33percentfund/'> <i class="ri-instagram-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
          <a href='https://twitter.com/33percentfund'>  <i class="ri-twitter-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
          <a href="https://www.youtube.com/@33PercentFund"  target='_blank'><i class="ri-youtube-fill"></i></a>
           
           </div>
         </div>
       </div>
      </section>
    </div>
  )
}

export default Footer;

