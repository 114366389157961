import { BLOG_LIST } from "../assets/BlogList.js";

import BlogIn from "./Blog_Ln.jsx";
export default function Blogina(){
    return     <section id="core-concepts">
    <ul >
    <h2 id="textt" style={{fontFamily:"Poppins",fontWeight:"400",fontSize:"14px",color:"#42526E"}}>Popular Blogs</h2>
{BLOG_LIST.map((conceptItem)=>
<BlogIn key={conceptItem.title} {...conceptItem}/>)}
    </ul>
</section>


}