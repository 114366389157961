import BLOG23 from './images/blg23a-min.png';
import BLOG22 from '../pages/blg22a-min.png';
import BLOG21 from './images/bl19-min.png';

export const BLOG_LIST = [
  {
    image: BLOG23,
    title: 'Value Investing: The Time to Buy is When There’s Blood on the Streets',
   Link: 'Value-Investing'
  }, 
  {
    image: BLOG21,
    title: "Donald Trump’s Financial and Trade Policies",
   Link: "Donald-Trump’s-Financial-and-Trade-Policies"
  },

  {
    image: BLOG22,
    title: 'DeepSeek’s AI-Disruption Wipes $108 Billion from the Market',
   Link: "DeepSeek’s-AI-Disruption-Wipes-$108-Billion-from-the-Market"
  },

  ];
  
