import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/home.css'
import '../components/Styles/about.css'
import m1 from '../assets/images/m1.png'
import m2 from '../assets/images/m2.png'
import m3 from '../assets/images/m3.png'
import new1 from '../assets/images/wealthh-min.png'
import new2 from '../assets/images/wealthh2-min.png'
import m4 from '../assets/images/images4.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Home = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
          <Helmet>
      
      <title>Unlock Your Journey to Financial Success with Personalized Guidance - 33 Percent Fund</title>
      <meta name="description" content="Unlock Your Financial Potential and explore Your Personalized Path to Success with Expert Guidance. Start Your Journey to Financial Freedom Today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
        <section className='about__us' >
            <h1 style={{paddingTop:"180px"}} data-aos="zoom-in">Welcome to 33 Percent Fund</h1>
            <p data-aos="zoom-in">
Your Path to Financial Success</p>
        </section>

       

   <section className='mid_sec'>

     <div className='row'>
        <div className='col' >
          <h2 data-aos="fade-right">Unlock Your Financial Potential</h2>
          <br />
          <p data-aos="fade-right">At 33% Fund, we're committed to delivering consistent, high annual returns through our specialized dividend-oriented investment strategies. 
<br /><br />
Our unique multi-strategy approach not only ensures attractive returns but also maintains moderate volatility and low beta to the S&P 500. With our investment expertise, you can confidently pave your way towards financial prosperity.
<br /><br />
Empowering Investments Worth Over $2 Billion: Over the past 5 years, we've facilitated investments totaling more than $2 billion, creating substantial value for our clients.</p>
        </div>
        <div className='col' data-aos="fade-left">
          <img src={m1} alt="image" />
        </div>
     </div>


     <div className='row'>
        <div className='col'>
          <img src={m2} alt="image"  data-aos="fade-right"/>
        </div>
        <div className='col' >
          <h2 data-aos="fade-left">Discover Your Personalized Path to Financial Success</h2>
          <p data-aos="fade-left">Delve into our diverse array of investment strategies, thoughtfully crafted to align seamlessly with your unique financial goals. From dividend-oriented strategies that generate income to approaches focused on special situations, our range of strategies aims to maximize your returns while effectively managing risk.</p>
        </div>
     </div>

     <div className='row'>
        <div className='col' >
     
          <h2 data-aos="fade-right">Stay Ahead of
Market Trends:</h2>
          <p data-aos="fade-right">Stay updated with the latest news, market insights, and trends that directly impact our investment strategies. Our team of experts diligently analyzes the market landscape to empower you with the knowledge you need to make informed investment decisions.</p>
     
        </div>
        <div className='col' data-aos="fade-left">
        <img src={m3} alt="image" />
        </div>
     </div>
     

     <div className='row'>
        <div className='col' data-aos="fade-left" >
     
          <img src={m4} alt="image" />
        </div>

       <div className='col' >
       <h2 data-aos="fade-right">Our Investment Philosophy</h2>
          <p data-aos="fade-right">We believe that investment success lies in the balance between risk and reward. Our unique multi-strategy approach is backed by rigorous academic research and years of real-world experience, bringing you the best of both traditional and innovative investment techniques.</p>        
        </div>
     </div>
      </section>
<br /><br /><br />
      <section className='last_sec'>
        <div>
     <h2 id='h222a' data-aos="fade-right">Upcoming Events and Webinars</h2>
     <p id='h222b' data-aos="fade-right">Stay engaged with 33% Fund's thought leadership through our series of workshops, webinars, and speaking engagements designed to deepen your understanding of investment strategies, market trends, and financial well-being. 
<br /> <br />
Our experts are committed to providing you with invaluable insights and actionable advice. Reserve your spot today by signing up directly through our website.</p>
</div>
      </section>
      <br /><br />

      <section>
         <div className='newsec'>
          <div className='rown'>
          <div className='colnn'>
            <h3>Coming soon</h3>
            <br />
            <p>The WealthPath Conference is dedicated to advancing knowledge and understanding of Shariah-compliant investments among financial professionals, investors, and enthusiasts.</p>
            <br />
             <p>Date: May 17, 2025 <br />Venue: Wyndham Houston near NRG Park </p>
             <br />
             <span style={{display:"none"}}><button>Website</button> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; <button>Buy Tickets</button></span>
          </div>
          <div className='colnnb'>
         <img src={new1} alt="" />
          </div>
          </div>
         </div>
       </section>
<br /><br />
<section>
  <br />
<div className='newsec'>
          <div className='rown'>
          <div className='colnn'>
            <p>The 33% Fund is a unique investment fund designed for those seeking to align their financial goals with Sharia-compliant principles. Our expertise lies in self-directed IRAs and ethical investments that prioritize transparency and balance.
<br /><br />
The fund’s name, 33% Fund, reflects its strategic diversification across three key sectors:
<br /><br />
	1.	High-Growth Innovation: 33% of the portfolio focuses on cutting-edge, high-tech investments, with a strong emphasis on AI-driven opportunities.
  <br /><br />
	2.	Dividend-Powered Stability: Another 33% is dedicated to established, dividend-oriented companies—household names you know and trust.
  <br /><br />
	3.	Real Estate Ventures: The final 33% targets promising real estate projects, offering tangible assets and long-term growth.
  <br /><br />
At 33% Fund, we blend innovation, stability, and tangible assets to provide a well-rounded, Sharia-compliant investment strategy tailored to your needs.</p>
        </div>
        <div className='colnnb'>
         <img src={new2} alt="" />
        </div>
        </div>
        </div>
</section>
<br /><br />
       <section className='last__sec2'>
           <div className='last_sec_md' data-aos="zoom-in">
            <h2>Get Started Today</h2>
            <p>Ready to embark on your investment journey with us? Contact our investor relations team to get started or schedule a one-on-one consultation with one of our portfolio managers.</p>
              <button ><a href="https://33percentfund.cashflowportal.com/offering/d9112cb28044441c9229ee7323d373c6">Book a Consultation</a></button>
          
           </div>
       </section>
    </div>
  )
}

export default Home