import '../components/Styles/blogin.css'
export default function  Blog_Ln ({image,title,Link}){
    return (
      <div className='ln_top'>
         <div className='ln_top_row'>
            
            <div className='ln_top_col'>
               <a href={Link}><img  src={image} alt="" /></a>

            </div>
             
             <div className='ln_top_col'> 
             <a href={Link}><h3 style={{fontFamily:"Poppins",fontWeight:"400",fontSize:"18px",color:"#172B4D"}}>{title}</h3></a>
               </div>
         </div>
             <hr className='new1'/>
       </div>
    );
  }
  